<template>
  <div>
    <div class="mt-4">
      <v-data-table
        :headers="headers"
        :items="cores"
        sort-by="descricao"
        class="border"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Cores registradas</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon v-if="canList" medium @click="getCores" title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canCreate"
                  color="primary"
                  dark
                  class="mb-2"
                  v-on="on"
                  >Nova Cor</v-btn
                >
              </template>
              <cadastro-cor
                :cor="cor"
                :formTitle="formTitle"
                @close="close"
                :corIndex="corIndex"
              />
            </v-dialog>

            <v-dialog v-model="dialogDelete" max-width="400px">
              <v-card>
                <v-card-title class="v-card mb-4">
                  <span class="headline">Deletar cor</span>
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="black darken-1" text @click="closeDeleteItem"
                    >Cancelar</v-btn
                  >
                  <v-btn
                    color="darken-1"
                    style="background: #f00; color: white"
                    @click="deletarCor"
                    >Deletar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon v-if="canUpdate" small class="mr-2" @click="editItem(item)"
            >mdi-pencil</v-icon
          >
          <v-icon v-if="canDelete" small @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>


        <template v-slot:no-data>
          <v-btn v-if="canList" color="primary" @click="getCores"
            >Reset</v-btn
          >
          <div v-if="!canList">
            <span class="red--text"
              >Você não tem permissão para visualizar as informações desta
              tela</span
            >
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CadastroCor from './CadastroCor.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  components: { CadastroCor },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      corIndex: -1,
      cor: {
        descricao: '',
      },
    }
  },
  computed: {
    ...mapState('patio', ['cores']),
    ...mapState('auth', ['empresaAtual']),
    
    permission() {
      return Sequences.SistemaVeiculoCores.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
    
    formTitle() {
      return this.corIndex === -1 ? 'Adicionar nova cor' : 'Editar cor'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    if (this.canList) {
      await this.getCores()
    }
  },

  methods: {
    ...mapActions('patio', ['getCores', 'addCor', 'editCor', 'deleteCor']),

    editItem(item) {
      this.corIndex = this.cores.indexOf(item)
      Object.assign(this.cor, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.dialogDelete = true
      this.corIdDelete = item.id
    },

    closeDeleteItem() {
      this.dialogDelete = false
      this.corIdDelete = -1
    },

    deletarCor() {
      if (this.corIdDelete > -1) {
        this.deleteCor(this.corIdDelete)
      }
      this.corIdDelete = -1
      this.dialogDelete = false
    },

    close() {
      this.corIndex = -1
      this.dialog = false
    },
  },
}
</script>
